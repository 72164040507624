import React from "react";
export const StoreContext = React.createContext(null);

const Store = ({ children }) => {
  const [sharing, setSharing] = React.useState({});
  const [help, setHelp] = React.useState({});

  const onSetSharing = value => {
    setSharing(state => Object.assign(state, value));
  };

  const onSetHelp = value => {
    setHelp(state => Object.assign(state, value));
  };

  const store = {
    sharing,
    help,
    setSharing: onSetSharing,
    setHelp: onSetHelp
  } as any;

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default Store;
