import AuthClient from './client/AuthClient';

const refreshToken = (params: any) => {
	return AuthClient.put('/login/refresh-token', params);
};
const getUser = (params: any) => {
	return AuthClient.get('/permissions/salons', params);
};

const getMenu = (domain) => {
	return AuthClient.get(`/permissions/menus`, { params: { domain: domain } });
};

const getPermissions = (domain) => {
	return AuthClient.get(`/permissions/actions`, { params: { domain: domain } });
};

export default {
	refreshToken,
	getUser,
	getMenu,
	getPermissions
};
