import OrganizationClient from './client/OrganizationClient';

const getGroupManager = (params: any) => {
	// 1 salon - 2 van phong
	return OrganizationClient.get('/get-group-manager', { params });
};
const getStaffByManagerId = (params: any) => {
	return OrganizationClient.get('/get-staff-by-manager', { params });
};
const getStaffBySalonId = (params: any) => {
	return OrganizationClient.get('/get-staff-by-salon', { params });
};
const getSalonByAsmId = (params: any) => {
	return OrganizationClient.get('/get-salons-by-asm', { params });
};
const getListStaff = (params: any) => {
	return OrganizationClient.get('/get-list-staff', { params });
};
const getCurrentStaff = (params: any) => {
	return OrganizationClient.get('/get-current-staff', { params });
};
const getListOfficeDepartment = () => {
	return OrganizationClient.get('/get-list-office-department-header');
};
const getStaffOfficeByDepartment = (params: any) => {
	return OrganizationClient.get('/get-staff-by-office-department', { params });
};

export default {
	getGroupManager,
	getStaffByManagerId,
	getStaffBySalonId,
	getSalonByAsmId,
	getListStaff,
	getCurrentStaff,
	getListOfficeDepartment,
	getStaffOfficeByDepartment,
};
