export const INCREMENT_LOADING = 'user/INCREMENT_LOADING';
export const DECREMENT_LOADING = 'user/DECREMENT_LOADING';
export const GET_USER = 'user/GET_USER';
export const SET_ROUTE_CONFIGS = 'user/SET_ROUTE_CONFIGS';
export const SET_MENU_PATHS = 'user/SET_ROUTE_CONFIGS';
export const SET_PERMISSION_LIST = 'user/SET_PERMISSION_LIST';

export const IncrementLoading = {
	payload: 1,
	type: INCREMENT_LOADING,
};

export const DecrementLoading = {
	payload: 1,
	type: DECREMENT_LOADING,
};

export const setUser = (payload) => {
	return {
		payload,
		type: GET_USER,
	};
};

export const setRouteConfigs = (payload) => {
	return {
		payload,
		type: SET_ROUTE_CONFIGS,
	};
};

export const setMenuPaths = (payload) => {
	return {
		payload,
		type: SET_MENU_PATHS,
	};
};

export const setPerMissionList = (payload) => {
	return {
		payload,
		type: SET_PERMISSION_LIST,
	};
};