import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Loading from '../components/DashboardLayout/Loading';
import Page403 from 'pages/Errors/403';
import Page500 from 'pages/Errors/500';
import Page404 from 'pages/Errors/404';
import PrivateRoute from 'components/PrivateRoute';
const OrgChart = React.lazy(() => import('pages/OrgChart'));
const Home = React.lazy(() => import('pages/Home'));

function MainRouter(propsRouter) {
	return (
		<div>
			<Suspense fallback={<Loading />}>
				<Switch>
					<PrivateRoute exact path={'/'} component={Home} {...propsRouter} />
					<PrivateRoute
						exact
						path="/organization-chart"
						component={OrgChart}
						{...propsRouter}
					/>
					<PrivateRoute path="/403" component={Page403} {...propsRouter} />
					<PrivateRoute path="/500" component={Page500} {...propsRouter} />
					<PrivateRoute path="*" component={Page404} {...propsRouter} />
				</Switch>
			</Suspense>
		</div>
	);
}

export default withRouter(MainRouter);
