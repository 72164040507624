import Page403 from 'pages/Errors/403';
import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { Mocks } from 'utils';
import { orEmpty } from 'utils/Selector';

function PrivateRoute(props) {
	const { component: Component, state, ...others } = props;
	const history = useHistory();
	const location = useLocation();
	const listMenu = orEmpty('userReducer.menu', state);

	async function getListMenus() {
		try {
			if (listMenu.length > 0) {
				const result = listMenu.find(
					(item) => item.key === '/' + location.pathname.split('/')[1]
				);
				if (result) return;
				history.push('/403')
			}
		} catch {
			console.log('error');
		}
	}

	useEffect(() => {
		getListMenus();
	}, [listMenu]);

	return (
		<Route
			{...others}
			render={(props) => {
				return <Component {...others} {...props} /> ;
			}}
		/>
	);
}
export default PrivateRoute;
