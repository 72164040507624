import axios, { AxiosResponse } from 'axios';

export default function getInstanceAxios(baseAPI: string) {
	const instance = axios.create({
		baseURL: baseAPI,
	});

	instance.interceptors.request.use(
		function (config) {
			const accessToken = localStorage.getItem('AccessToken');
			config.headers = {
				'Content-Type': 'application/json',
				Authorization: accessToken ? `Bearer ${accessToken}` : ''
			};
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	instance.interceptors.response.use(
		function (response: AxiosResponse) {
			try {
				if (response.status !== 200) return Promise.reject(response.data);
				return response.data;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		function (error) {
			if (error && error.response) {
				const { status } = error.response;
				if (status === 401) {
					localStorage.removeItem('accessToken');
				}
			}
			return Promise.reject(error);
		}
	);
	return instance;
}
