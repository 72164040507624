import React from "react";
import DashboardLayout from "../layouts/DashboardLayout";


export default function Index() {
    return (
        <>
            <DashboardLayout/>
        </>
    )
}