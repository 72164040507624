import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Dropdown, Button, Menu, Space } from 'antd';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	DownOutlined,
	LogoutOutlined,
} from '@ant-design/icons';
import MainMenu from '../components/DashboardLayout/MainMenu';
import './DashboardLayout.scss';
import MainRouter from '../routes/MainRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import logo from '../assets/images/ic_logo_white.png';
import userReducer from './reducer';
import { withReducer } from 'hoc';
import { orEmpty, orNull } from 'utils/Selector';
import { useMediaQuery } from 'react-responsive';

const { Header, Sider, Content } = Layout;

function DashboardLayout(props) {
	const { dispatch, action, state } = props;
	const isMinPC = useMediaQuery({
		query: '(max-width: 1450px)',
	});
	const [collapsed, setCollapsed] = useState(isMinPC);
	const [user, setUser] = useState(null);

	const toggleMenu = () => {
		setCollapsed(!collapsed);
	};

	useEffect(() => {
		action.userReducer.onGetUser({}, dispatch.userReducer);
	}, []);

	useEffect(() => {
		if (orNull('userReducer.user', state)) {
			setUser(orNull('userReducer.user', state));
			action.userReducer.setCurrentMenu(dispatch.userReducer);
		}
	}, [orNull('userReducer.user', state)]);

	const logOut = async () => {
		await localStorage.removeItem('IdToken');
		await localStorage.removeItem('AccessToken');
		await localStorage.removeItem('RefreshToken');
		await localStorage.removeItem('UserID');
		// @ts-ignore
		await ssoLogout();
	};

	const menu = (
		<Menu>
			<Menu.Item key="1" icon={<LogoutOutlined />} onClick={logOut}>
				Đăng xuất
			</Menu.Item>
		</Menu>
	);

	const ComponentLayout = useCallback(() => {
		return (
			<Layout id="e-library-web">
				<Sider
					className="sider-layout-sidebar"
					trigger={null}
					collapsible
					collapsed={collapsed}
					breakpoint="xs"
				>
					<div className="logo">
						<a href="/">
							<img src={logo} alt="" />
						</a>
					</div>
					<MainMenu listMenu={orEmpty('userReducer.menu', state)} />
				</Sider>
				<Layout className={`${collapsed ? 'site-layout-true' : 'site-layout'}`}>
					<Header className="header" style={{ padding: 0, background: '#fff' }}>
						<div className={'trigger'} onClick={toggleMenu}>
							{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
						</div>
						<Space />
						<Dropdown overlay={menu}>
							<Button>
								Xin chào{' '}
								<strong style={{ marginLeft: 10 }}>{user?.fullName}</strong>{' '}
								<DownOutlined />
							</Button>
						</Dropdown>
					</Header>
					<Content className="site-layout-background content">
						<MainRouter user={user} state={state} />
					</Content>
				</Layout>
			</Layout>
		);
	}, [user, collapsed]);

	return (
		<Router>
			<ComponentLayout />
		</Router>
	);
}

export default withReducer({
	key: 'userReducer',
	...userReducer,
})(DashboardLayout);
