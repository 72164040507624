import { LineChartOutlined, ClusterOutlined, ShopOutlined } from '@ant-design/icons';

export const menus = [
	{
		key: '/',
		path: '/',
		title: 'Tổng quan',
		icon: <LineChartOutlined />,
		nested: [],
	},
	{
		key: '/organization-chart',
		path: '/organization-chart',
		title: 'Sơ đồ tổ chức',
		icon: <ClusterOutlined />,
		nested: [
			{
				key: '/organization-chart?groupid=2',
				path: '/organization-chart?groupid=2',
				title: 'Khối văn phòng',
				icon: <LineChartOutlined />,
			},
			{
				key: '/organization-chart?groupid=1',
				path: '/organization-chart?groupid=1',
				title: 'Khối salon',
				icon: <ShopOutlined />,
				nested: [],
			},
		],
	},
];
