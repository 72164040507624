import api from 'repository';
import { Mocks } from 'utils';
import {
	IncrementLoading,
	DecrementLoading,
	setUser,
	setPerMissionList,
	setMenuPaths,
	setRouteConfigs,
} from './action-type';

const listToTree = (list, tree, parentItem?) => {
	list.forEach((item) => {
		let child = {
			...item,
		};
		if (parentItem) {
			child.parentItem = parentItem;
		}
		if (item.children && item.children.length) {
			listToTree(item.children, tree, {
				name: item.name,
				pageId: item.pageId,
				meta: item.meta,
			});
		}
		delete child.children;
		tree.push(child);
	});
};

const filterMenu = (routes, menus) => {
	const routesAccess = [routes[0]];
	[...routes].map((item) => {
		if (menus.find((x) => x.pageId === item.path)) {
			routesAccess.push(item);
		}
	});
	return routesAccess;
};

export const onGetUser = async (params, dispatch) => {
	try {
		const response = await api.getUser(params);
		dispatch(
			setUser({
				user: response,
			})
		);
	} catch (error) {
		dispatch(
			setUser({
				user: null,
				message: 'Đã xảy ra lỗi vui lòng quay lại sau',
				type: 'error',
			})
		);
	}
};

export const setPerMission = async (dispatch) => {
	try {
		const response = await api.getPermissions(process.env.REACT_APP_DOMAIN_PERMISSION);
		dispatch(
			setPerMissionList({
				permission: response,
			})
		);
	} catch (error) {
		dispatch(
			setPerMissionList({
				permission: null,
				message: 'Đã xảy ra lỗi vui lòng quay lại sau',
				type: 'error',
			})
		);
	}
};

export const setCurrentMenu = async (dispatch) => {
	try {
		let routeConfigs = Mocks.MENUS.menus;
		let routes: any = [];
		let menuPaths: any = [];
		
		routes = await api.getMenu(process.env.REACT_APP_DOMAIN_PERMISSION);
		listToTree(routes, menuPaths);

		const menuDomain: any = [...menuPaths].filter(
			(item) => item.domain === process.env.REACT_APP_DOMAIN_PERMISSION
		);

		const currentRoutes = filterMenu(routeConfigs, menuDomain);
		dispatch(
			setMenuPaths({
				menuPaths: menuDomain,
			})
		);
		dispatch(
			setRouteConfigs({
				menu: currentRoutes,
			})
		);
	} catch (error) {}
};
