import * as action from './action';
import {
	INCREMENT_LOADING,
	DECREMENT_LOADING,
	GET_USER,
	SET_ROUTE_CONFIGS,
	SET_MENU_PATHS,
} from './action-type';

const initialState = {
	isLoading: false,
	counter: 0,
	user: null,
};

const reducer = (state, action) => {
	switch (action.type) {
		case INCREMENT_LOADING:
			return {
				...state,
				counter: state.counter + action.payload,
			};
		case DECREMENT_LOADING:
			return {
				...state,
				counter: state.counter - action.payload < 0 ? 0 : state.counter - action.payload,
				type: null,
				actionName: null,
				message: null,
			};
		case GET_USER:
			return {
				...state,
				...action.payload,
			};

		case SET_ROUTE_CONFIGS:
			return {
				...state,
				...action.payload,
			};

		case SET_MENU_PATHS:
			return {
				...state,
				...action.payload,
			};

		default:
			return state;
	}
};

export default {
	action,
	initialState,
	reducer,
};
