import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { orNull } from 'utils/Selector';

const { SubMenu } = Menu;


export default function MainMenu(props) {
	const location = useLocation();
	const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
	const {listMenu} =props

	useEffect(() => {
		setSelectedKeys(['/' + location.pathname.split('/')[1]]);
	}, [location.pathname]);
    
	return (
		<Menu
			theme="dark"
			mode="inline"
			defaultOpenKeys={['/' + location.pathname.split('/')[1]]}
			selectedKeys={selectedKeys}
			defaultSelectedKeys={[location.pathname]}
		>
			{listMenu && listMenu.map((item: any) => {
				if (item.nested.length) {
					return (
						<SubMenu key={item.key} icon={item.icon} title={item.title}>
							{item.nested.map((nestedItem) => {
								return (
									<Menu.Item key={nestedItem.key}>
										<Link to={nestedItem.path}>{nestedItem.title}</Link>
									</Menu.Item>
								);
							})}
						</SubMenu>
					);
				}
				return (
					<Menu.Item key={item.key} icon={item.icon}>
						<Link to={item.path}>{item.title}</Link>
					</Menu.Item>
				);
			})}
		</Menu>
	);
}
